import React, { useRef } from 'react';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { OverlayPanel } from 'primereact/overlaypanel';

const NavBar = ({ homeClick }) => {
  const menuRef = useRef(null);

  const startContent = (
    <>
      <div
        className="flex flex-row align-items-center gap-2 cursor-pointer hover:bg-white-alpha-10 transition-all transition-duration-200"
        style={{ borderRadius: '10px', padding: '0.5rem' }}
        onClick={homeClick}
      >
        <Image src="favicon.ico" width={40} height={40} />
        <h1 style={{ fontSize: '2rem', margin: 0 }}>Phennx.io</h1>
      </div>
      {/* Collapsed menu button (visible only on narrow screens) */}
      <div className='menu-toggle-wrapper'>
        <Button
          icon='pi pi-bars'
          className="menu-toggle-button p-link inline-flex justify-content-center align-items-center text-white hover:bg-white-alpha-10 transition-all transition-duration-200"
          style={{ display: 'none' }}
          onClick={(e) => menuRef.current.toggle(e)}
        />
        <OverlayPanel ref={menuRef}>
          <Button
            className="p-link inline-flex justify-content-center align-items-center text-white h-3rem w-3rem border-circle hover:bg-white-alpha-10 transition-all transition-duration-200"
            onClick={homeClick}
          >
            <i className="pi pi-home text-2xl"></i>
          </Button>
        </OverlayPanel>
      </div>
    </>
  );

  const centerContent = (
    <div
      className="center-content"
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
        height: '2.5rem',
      }}
    >
      <Button
        className="p-link inline-flex justify-content-center align-items-center text-white h-3rem w-3rem border-circle hover:bg-white-alpha-10 transition-all transition-duration-200"
        onClick={homeClick}
      >
        <i className="pi pi-home text-2xl"></i>
      </Button>
    </div>
  );

  const endContent = (
    <>
      <div className="flex align-items-center gap-2"></div>
    </>
  );

  return (
    <div className="navbar mt-4" style={{ borderRadius: '80px' }}>
      <Toolbar
        start={startContent}
        center={<div className="center-content-wrapper">{centerContent}</div>}
        end={endContent}
        style={{ borderRadius: '80px' }}
      />
    </div>
  );
};

export default NavBar;
