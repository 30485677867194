import React from 'react';
import { Button } from 'primereact/button';

const HeroSection = React.forwardRef(({ onStayInformedClick }, ref) => {
  return (
    <div ref={ref} className="hero-section" style={styles.container}>
      <video autoPlay muted loop style={styles.video}>
        <source src={`${process.env.PUBLIC_URL}/hero-video.mp4`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="flex flex-column hero-content" style={styles.content}>
        <h1 className="p-text-center p-text-white" style={styles.title}>PHENNX.IO</h1>
        <p className="p-text-center p-text-white" style={styles.subtitle}>Coming soon.</p>
        <Button label="Stay Informed" className="p-button-rounded p-button-info" style={styles.button} onClick={onStayInformedClick} />
        <Button
          label="Follow us on LinkedIn"
          icon="pi pi-linkedin"
          className="p-button-rounded p-button-text mt-4"
          onClick={() => window.open('https://www.linkedin.com/company/phennx', '_blank')}
          style={{
            background: '#0077B5',
            color: '#ffffff',
            fontWeight: 'bold',
          }}
        />
      </div>
    </div>
  );
});

// Styling for the component
const styles = {
  container: {
    position: 'relative',
    width: '100%',
    height: '100vh',
    overflow: 'hidden'
  },
  video: {
    width: '100%',
    height: '100%',
    opacity: '70%',
    objectFit: 'cover'  // This makes the video cover the available space without stretching.
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center'
  },
  title: {
    fontSize: '4rem',  // Example size, adjust based on your design needs
    fontWeight: 'bold',
    marginBottom: '1rem'
  },
  subtitle: {
    fontSize: '1.5rem',
    marginBottom: '2rem'
  },
  button: {
    fontSize: '1.2rem',
    padding: '1rem 2rem',
    background: 'orange',
    backgroundColor: 'orange',
    border: '1px solid black'
  }
};

export default HeroSection;
