import React, { useRef } from 'react';
import NavBar from './components/NavBar';
import HeroSection from './components/HeroSection';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';

function App() {
  const heroRef = useRef(null);
  const contactFormRef = useRef(null);

  const scrollToHero = () => {
    heroRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToContactForm = () => {
    contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div style={{ paddingBottom: '60px' }}>
      <NavBar homeClick={scrollToHero} />
      <HeroSection ref={heroRef} onStayInformedClick={scrollToContactForm} />
      <ContactForm ref={contactFormRef} />
      <Footer />
    </div>
  );
}

export default App;
