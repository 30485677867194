import React from 'react';
import { Button } from 'primereact/button';

const Footer = () => {
  return (
    <div
      className="floating-footer p-d-flex p-flex-column p-flex-md-row p-jc-between p-ai-center p-shadow-2"
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        padding: '1rem',
        background: 'black',
        color: 'white',
        zIndex: 1000,
      }}
    >
      <div className="footer-logo p-mb-2 p-mb-md-0">
        <h4 style={{ margin: 0, fontWeight: 'bold' }}>© 2024 Phennx.io</h4>
      </div>

      <div className="footer-links p-d-flex p-flex-column p-flex-md-row p-mb-2 p-mb-md-0">
        <a
          href="#privacy"
          className="mr-3"
          style={{
            color: '#1ABC9C',
            textDecoration: 'none',
          }}
          onMouseEnter={(e) => (e.target.style.textDecoration = 'underline')}
          onMouseLeave={(e) => (e.target.style.textDecoration = 'none')}
        >
          Privacy Policy
        </a>
        <a
          href="#terms"
          className="mr-3"
          style={{
            color: '#1ABC9C',
            textDecoration: 'none',
          }}
          onMouseEnter={(e) => (e.target.style.textDecoration = 'underline')}
          onMouseLeave={(e) => (e.target.style.textDecoration = 'none')}
        >
          Terms of Service
        </a>
        <a
          href="#contact"
          style={{
            color: '#1ABC9C',
            textDecoration: 'none',
          }}
          onMouseEnter={(e) => (e.target.style.textDecoration = 'underline')}
          onMouseLeave={(e) => (e.target.style.textDecoration = 'none')}
        >
          Contact Us
        </a>
      </div>

      <div className="footer-actions">
        <Button
          icon="pi pi-linkedin"
          className="p-button-rounded p-button-text p-mr-2"
          style={{ color: '#1ABC9C' }}
          onClick={() => window.open('https://www.linkedin.com/company/phennx', '_blank')}
        />
        <Button
          icon="pi pi-facebook"
          className="p-button-rounded p-button-text p-mr-2"
          style={{ color: '#1ABC9C' }}
        />
        <Button
          icon="pi pi-twitter"
          className="p-button-rounded p-button-text p-mr-2"
          style={{ color: '#1ABC9C' }}
        />
        <Button
          icon="pi pi-instagram"
          className="p-button-rounded p-button-text"
          style={{ color: '#1ABC9C' }}
        />
      </div>
    </div>
  );
};

export default Footer;